<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <div>
        <v-row>
          <v-col cols="5">
            <v-card outlined class="mb-6">
              <v-card-title
                class="d-flex justify-start align-center grey lighten-3"
              >
                <div class="flex-grow-1">Contact</div>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p>This is the Inputted Contact Details</p>
                    <div class="contact-card">
                      <div class="contact-card-body">
                        <div class="contact-card-title">
                          {{ application.admins.first_name }}
                          {{ application.admins.last_name }}
                        </div>

                        {{ application.admins.line_1 }}
                        {{ application.admins.line_2 }}
                        {{ application.admins.line_3 }}
                      </div>
                    </div></v-col
                  >
                  <v-col cols="12" v-if="application.admins.customer_id">
                    <div class="green--text lighten-3 mb-3">
                      A Contact has been successfully linked to this Application
                      Form.
                    </div>
                  </v-col>
                  <v-col v-else>
                    <div class="red--text lighten-3 mb-3">
                      A Contact hasn't been linked to this application.
                    </div>

                    <div v-if="this.found == 'none'">
                      <div class="red--text lighten-3 mb-3">
                        There are not matches for this customer in the database
                      </div>
                      <v-btn
                        small
                        depressed
                        color="blue lighten-4 blue--text"
                        class="mr-2"
                        @click="openAddContact(application)"
                      >
                        Create a New Contact
                      </v-btn>
                    </div>

                    <div v-if="this.found == 'email'">
                      <div class="red--text lighten-3 mb-3">
                        A customer with this email address exists in the
                        database. Do you want to link this application form to
                        this Customer
                      </div>

                      <v-btn
                        small
                        depressed
                        color="blue lighten-4 blue--text"
                        class="mr-2"
                        @click="openLinkToExising(application)"
                      >
                        Link to an Exising Contact
                      </v-btn>
                    </div>

                    <div v-if="this.found == 'match'">
                      <div class="red--text lighten-3 mb-3">
                        There is a possible match for this customer already in
                        the database
                      </div>

                      <v-btn
                        small
                        depressed
                        color="blue lighten-4 blue--text"
                        class="mr-2"
                        @click="$refs.contactForm.openForm(application)"
                      >
                        Link to an Exising Contact
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="7">
            <div>
              <v-card>
                <v-tabs
                  background-color="grey lighten-3"
                  v-model="tab"
                  @change="onTabChange"
                >
                  <v-tab>General</v-tab>
                  <v-tab>Maintenance</v-tab>
                  <v-tab>Confirmation Email</v-tab>
                </v-tabs>

                <v-tabs-items v-model="currentTab">
                  <v-tab-item>
                    <v-card>
                      <v-card-text
                        ><v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-simple-table class="mb-5 table" divider="true">
                                <tbody>
                                  <tr>
                                    <th colspan="5">Payments</th>
                                  </tr>

                                  <tr>
                                    <th>Deposit Request</th>
                                    <th>Deposit Received</th>
                                    <th>Email Confirmation</th>
                                    <th>Interim</th>
                                    <th>Final/Balance</th>
                                  </tr>

                                  <tr>
                                    <td>
                                      <div>
                                        <v-switch
                                          color="green"
                                          v-model="
                                            fields.payments_deposit_request
                                          "
                                          @click="
                                            openSwitch(
                                              application,
                                              'payments_deposit_request'
                                            )
                                          "
                                          inset
                                          :error="
                                            errors.hasOwnProperty(
                                              'payments_deposit_request'
                                            )
                                          "
                                          :error-messages="
                                            errors['payments_deposit_request']
                                          "
                                        ></v-switch>
                                      </div>
                                    </td>

                                    <td>
                                      <div>
                                        <v-switch
                                          color="green"
                                          v-model="fields.payment_deposit"
                                          inset
                                          @click="
                                            openSwitch(
                                              application,
                                              'payment_deposit'
                                            )
                                          "
                                        ></v-switch>
                                      </div>
                                    </td>

                                    <td>
                                      <div>
                                        <v-switch
                                          color="green"
                                          v-model="fields.email_confirmation"
                                          inset
                                          @click="
                                            openSwitch(
                                              application,
                                              'email_confirmation'
                                            )
                                          "
                                          :error="
                                            errors.hasOwnProperty(
                                              'email_confirmation'
                                            )
                                          "
                                          :error-messages="
                                            errors['email_confirmation']
                                          "
                                        ></v-switch>
                                      </div>
                                    </td>

                                    <td>
                                      <div>
                                        <v-switch
                                          v-model="fields.payments_interim"
                                          inset
                                          color="green"
                                          @click="
                                            openSwitch(
                                              application,
                                              'payments_interim'
                                            )
                                          "
                                          :error="
                                            errors.hasOwnProperty(
                                              'payments_interim'
                                            )
                                          "
                                          :error-messages="
                                            errors['payments_interim']
                                          "
                                        ></v-switch>
                                      </div>
                                    </td>

                                    <td>
                                      <div>
                                        <v-switch
                                          color="green"
                                          v-model="
                                            fields.payments_final_balance
                                          "
                                          @click="
                                            openSwitch(
                                              application,
                                              'payments_final_balance'
                                            )
                                          "
                                          inset
                                          :error="
                                            errors.hasOwnProperty(
                                              'payments_final_balance'
                                            )
                                          "
                                          :error-messages="
                                            errors['payments_final_balance']
                                          "
                                        ></v-switch>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>

                              <v-simple-table class="mb-5 table" divider="true">
                                <tbody>
                                  <tr>
                                    <th>Chosen Litter</th>
                                    <td>
                                      <v-autocomplete
                                        v-model="fields.litter_id"
                                        :items="litters"
                                        item-text="name"
                                        item-value="id"
                                        class="mb-5"
                                        hide-details
                                        outlined
                                        clearable
                                        background-color="white"
                                        @change="changeLitter()"
                                        :error="
                                          errors.hasOwnProperty('litter_id')
                                        "
                                        :error-messages="errors['litter_id']"
                                      ></v-autocomplete>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>First Name</th>
                                    <td>
                                      {{ application.admins.first_name }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Last Name</th>
                                    <td>
                                      {{ application.admins.last_name }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <th>Status</th>
                                    <td>
                                      <v-select
                                        label="Status"
                                        :items="statuses"
                                        class="mt-5"
                                        v-model="fieldsStatus.status"
                                        item-text="name"
                                        item-value="value"
                                        outlined
                                        background-color="white"
                                        @change="changeStatus()"
                                      ></v-select>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card-title class="d-flex justify-start align-center">
                      <div class="flex-grow-1"></div>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            depressed
                            color="blue lighten-4 blue--text"
                            v-on="on"
                            @click="
                              $refs.maintenanceForm.openForm(application.admins)
                            "
                          >
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Maintenance</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-simple-table class="mb-5 table" divider="true">
                      <tbody>
                        <tr>
                          <th>Contact ID</th>
                          <td>{{ application.admins.id }}</td>
                        </tr>
                        <tr>
                          <th>Hashed ID</th>
                          <td>{{ application.admins.hashed_id }}</td>
                        </tr>
                        <tr>
                          <th>Breed</th>
                          <td>
                            <div v-if="application.admins.breed">
                              {{ application.admins.breed.name }}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card>
                      <v-card-text>
                        <v-form
                          @submit.prevent="saveForm"
                          method="post"
                          id="message-form"
                        >
                          <tiptap
                            class="mb-5"
                            label="Message*"
                            v-model="fieldsMessage.message"
                            :error="errors.hasOwnProperty('message')"
                            :error-messages="errors['message']"
                          />
                        </v-form>

                        <v-btn
                          color="green"
                          text
                          type="submit"
                          :loading="loading"
                          form="message-form"
                          >Send Confirmation Email</v-btn
                        >
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-card outlined class="mb-6">
              <v-card-title
                class="d-flex justify-start align-center grey lighten-3"
              >
                <div class="flex-grow-1"></div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      @click="$refs.applicationForm.openForm(application)"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Details</span>
                </v-tooltip>
              </v-card-title>
              <v-divider></v-divider>
              <v-simple-table class="mb-5 table" divider="true">
                <tbody>
                  <tr>
                    <th>Daytime telephone</th>
                    <td>
                      <div>
                        {{ application.admins.daytime_telephone }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Evening telephone</th>
                    <td>
                      <div>
                        {{ application.admins.evening_telephone }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>
                      {{ application.admins.line_1 }}
                      {{ application.admins.line_2 }}
                      {{ application.admins.line_3 }}

                      {{ application.admins.postcode }}
                    </td>
                  </tr>
                  <tr>
                    <th>Mobile</th>
                    <td>
                      <div>{{ application.admins.phone }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>
                      <div>{{ application.admins.email }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Please list each family/household member's name,
                      occupation and age (where appropriate)
                    </th>
                    <td>
                      <div>{{ application.list_household_members }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Please detail the primary and secondary care-givers'
                      working schedule
                    </th>
                    <td>
                      <div>{{ application.carers_work_schedule }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Please detail provisions for the care of the dog when you
                      are at work or not at home
                    </th>
                    <td>
                      <div>{{ application.care_provisions }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Please detail you previous experience as dog owners/carers
                    </th>
                    <td>
                      <div>{{ application.previous_ownership_experience }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Please detail any other pets and animals currently living
                      on your property
                    </th>
                    <td>
                      <div>{{ application.additional_animals }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Does anybody in your household have a disability, disorder
                      or particular need which should be taken into
                      consideration? If so, please provide details
                    </th>
                    <td>
                      <div>{{ application.household_disability }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Please details your plans for training your puppy. Who,
                      when, where, how long for?
                    </th>
                    <td>
                      <div>{{ application.puppy_training_details }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>Where will your dog be living?</th>
                    <td>
                      <div>{{ application.dog_living_conditions }}</div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>

            <v-card outlined class="mb-6">
              <v-card-title
                class="d-flex justify-start align-center grey lighten-3"
              >
                <div class="flex-grow-1">
                  What coat type would you like your puppy to have?
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      @click="$refs.applicationForm.openForm(application)"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Terms Details</span>
                </v-tooltip>
              </v-card-title>
              <v-divider></v-divider>

              <v-simple-table class="mb-5 table" divider="true">
                <tbody>
                  <tr>
                    <td>First Choice</td>
                    <td>Second Choice</td>
                    <td>Third Choice</td>
                  </tr>
                  <tr>
                    <td>
                      <div v-if="application.size_first_choice === 'medium'">
                        Medium (17-21" at shoulder, 15-25kg)
                      </div>
                      <div
                        v-else-if="
                          application.size_first_choice === 'large_miniature'
                        "
                      >
                        Large Miniature/Small Medium (16-18" at shoulder,
                        10-18kg)
                      </div>
                      <div
                        v-else-if="
                          application.size_first_choice === 'miniature'
                        "
                      >
                        Miniature (13-17" at shoulder, 8-15kg)
                      </div>
                    </td>
                    <td>
                      <div v-if="application.size_second_choice === 'medium'">
                        Medium (17-21" at shoulder, 15-25kg)
                      </div>
                      <div
                        v-else-if="
                          application.size_second_choice === 'large_miniature'
                        "
                      >
                        Large Miniature/Small Medium (16-18" at shoulder,
                        10-18kg)
                      </div>
                      <div
                        v-else-if="
                          application.size_second_choice === 'miniature'
                        "
                      >
                        Miniature (13-17" at shoulder, 8-15kg)
                      </div>
                    </td>
                    <td>
                      <div v-if="application.size_third_choice === 'medium'">
                        Medium (17-21" at shoulder, 15-25kg)
                      </div>
                      <div
                        v-else-if="
                          application.size_third_choice === 'large_miniature'
                        "
                      >
                        Large Miniature/Small Medium (16-18" at shoulder,
                        10-18kg)
                      </div>
                      <div
                        v-else-if="
                          application.size_third_choice === 'miniature'
                        "
                      >
                        Miniature (13-17" at shoulder, 8-15kg)
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>

            <v-card outlined class="mb-6">
              <v-card-title
                class="d-flex justify-start align-center grey lighten-3"
              >
                <div class="flex-grow-1">
                  What coat type would you like your puppy to have?
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Terms Details</span>
                </v-tooltip>
              </v-card-title>
              <v-divider></v-divider>

              <v-simple-table class="mb-5 table" divider="true">
                <tbody>
                  <tr>
                    <td>First Choice</td>
                    <td>Second Choice</td>
                    <td>Third Choice</td>
                  </tr>

                  <tr>
                    <td>
                      <div>{{ application.coat_type_first_choice }}</div>
                    </td>
                    <td>
                      <div>{{ application.coat_type_second_choice }}</div>
                    </td>
                    <td>
                      <div>{{ application.coat_type_third_choice }}</div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>

            <v-card outlined class="mb-6">
              <v-card-title
                class="d-flex justify-start align-center grey lighten-3"
              >
                <div class="flex-grow-1"></div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Terms Details</span>
                </v-tooltip>
              </v-card-title>
              <v-divider></v-divider>
              <v-simple-table class="mb-5 table" divider="true">
                <tbody>
                  <tr>
                    <th>Must your puppy be non-shedding?</th>
                    <td>
                      <div>{{ application.non_shedding ? "Yes" : "No" }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Are you open to having a puppy with any coat colour?
                    </th>
                    <td>
                      <div>
                        {{ application.coat_colour_important ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      We place female puppies in Family Care Homes up to 1.5
                      hours drive away from LHL. We place male puppies in Family
                      Care Homes up to 20 minutes drive away from LHL. Please
                      select one of the following options
                    </th>
                    <td>
                      <div
                        v-if="application.geo_boundary_description == 'either'"
                      >
                        I live within 20 mins drive and am open to either a male
                        or female
                      </div>

                      <div
                        v-else-if="
                          application.geo_boundary_description == 'male20'
                        "
                      >
                        I live within 20 mins drive and would specifically like
                        to wait for a male
                      </div>
                      <div
                        v-else-if="
                          application.geo_boundary_description == 'female20'
                        "
                      >
                        I live within 20 mins drive and would specifically like
                        to wait for a female
                      </div>

                      <div
                        v-else-if="
                          application.geo_boundary_description == 'female1_5'
                        "
                      >
                        I live between 20 mins and 1.5 hours drive and am happy
                        to have a female
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Is there anything else you think we should know?</th>
                    <td>
                      <div>{{ application.anything_else_to_tell }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>How did you find out about us?</th>
                    <td>
                      <div>{{ application.found_lhl }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>Indicate your consent.</th>
                    <td>
                      <div>{{ application.data_consent ? "Yes" : "No" }}</div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card outlined class="mb-6">
              <v-card-title
                class="d-flex justify-start align-center grey lighten-3"
              >
                <div class="flex-grow-1">Photos</div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Terms Details</span>
                </v-tooltip>
              </v-card-title>
              <v-divider></v-divider>
              <v-simple-table class="mb-5 table" divider="true">
                <tbody>
                  <tr>
                    <th>Photographic identification</th>
                    <td>
                      <v-btn
                        v-if="application.photo_identification"
                        dark
                        small
                        class="mt-2"
                        depressed
                        :href="application.asset_urls.photo_identification"
                        target="_blank"
                      >
                        <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <th>Proof of Address</th>
                    <td>
                      <v-btn
                        v-if="application.proof_of_address"
                        dark
                        small
                        class="mt-2"
                        depressed
                        :href="application.asset_urls.proof_of_address"
                        target="_blank"
                      >
                        <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                      </v-btn>
                    </td>
                  </tr>

                  <tr>
                    <th>Photos of your enclosed garden</th>
                    <td>
                      <v-btn
                        v-if="application.photo_enclosed_garden"
                        dark
                        small
                        class="mt-2"
                        depressed
                        :href="application.asset_urls.photo_enclosed_garden"
                        target="_blank"
                      >
                        <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                      </v-btn>
                    </td>
                  </tr>

                  <tr>
                    <th>Any other documents you feel may be useful</th>
                    <td>
                      <v-btn
                        v-if="application.other_documents"
                        dark
                        small
                        class="mt-2"
                        depressed
                        :href="application.asset_urls.other_documents"
                        target="_blank"
                      >
                        <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-card outlined class="mb-6">
              <v-card-title
                class="d-flex justify-start align-center grey lighten-3"
              >
                <div class="flex-grow-1">Terms</div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Terms Details</span>
                </v-tooltip>
              </v-card-title>
              <v-divider></v-divider>

              <v-simple-table class="mb-5 table" divider="true">
                <tbody>
                  <tr>
                    <th>We want our dog to have puppies</th>
                    <td>
                      <div>
                        {{ application.term_accept_breeding ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>We own our own home</th>
                    <td>
                      <div>
                        {{ application.term_own_our_home ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>We have a fully enclosed garden</th>
                    <td>
                      <div>
                        {{
                          application.term_have_fully_enclosed_garden
                            ? "Yes"
                            : "No"
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      We live within a 1.5 hour drive (females) or a 20 min
                      drive (males) of Lomond Hills Labradoodles (LHL)
                    </th>
                    <td>
                      <div>
                        {{
                          application.term_within_geo_boundaries ? "Yes" : "No"
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>We are willing to feed the diet chosen by LHL</th>
                    <td>
                      <div>
                        {{ application.term_accept_diet ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      We are willing to adhere to basic care guidelines from LHL
                    </th>
                    <td>
                      <div>
                        {{
                          application.term_adhere_to_basic_care_guidelines
                            ? "Yes"
                            : "No"
                        }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      We will attend puppy training classes for at least 8 weeks
                    </th>
                    <td>
                      <div>
                        {{
                          application.term_attend_puppy_training ? "Yes" : "No"
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      We will openly and honestly communicate about all
                      dog-related matters
                    </th>
                    <td>
                      <div>
                        {{
                          application.term_open_honest_communications
                            ? "Yes"
                            : "No"
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      We will allow LHL breeders to visit our home when required
                    </th>
                    <td>
                      <div>
                        {{ application.term_lhl_visitation ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      We will cooperate with LHL to coordinate breeding the dog
                      for up to three or four litters (females) or up to six
                      years (males)
                    </th>
                    <td>
                      <div>
                        {{
                          application.term_breeding_coordination ? "Yes" : "No"
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      We will work with LHL to complete any health testing that
                      LHL deem necessary
                    </th>
                    <td>
                      <div>
                        {{
                          application.term_complete_health_testing
                            ? "Yes"
                            : "No"
                        }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      We are able and willing to transport the dog to and from
                      LHL when required
                    </th>
                    <td>
                      <div>{{ application.term_transport ? "Yes" : "No" }}</div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      We understand that LHL may need the dog to stay with them
                      from time to time
                    </th>
                    <td>
                      <div>
                        {{ application.term_lhl_sleep_over ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      We understand that we cannot opt out of the FCH programme
                      after joining
                    </th>
                    <td>
                      <div>
                        {{ application.term_cannot_opt_out ? "Yes" : "No" }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <MaintenanceForm ref="maintenanceForm" />
      <ContactForm ref="contactForm" type="fch" />
      <ApplicationForm ref="applicationForm" />

      <v-dialog scrollable v-model="exising.dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Link to Contact</v-card-title>
          <v-card-text
            >Are you sure you wish to link to exising contact?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetAddContact"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="exising.loading"
              @click="saveExisingContact"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        scrollable
        v-model="newContact.dialog"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">Create Contact</v-card-title>
          <v-card-text>Are you sure you wish to create contact?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetAddContact"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="newContact.loading"
              @click="saveAddContact"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";
import MaintenanceForm from "../components/MaintenanceForm.vue";
import ContactForm from "../components/ContactForm.vue";
import ApplicationForm from "../components/ApplicationFchForm.vue";

export default {
  components: {
    Tiptap,
    MaintenanceForm,
    ContactForm,
    ApplicationForm,
  },
  data() {
    return {
      found: null,
      newContact: {
        dialog: false,
        loading: false,
        application: {},
      },
      exising: {
        dialog: false,
        loading: false,
        application: {},
      },
      searchTerm: "",
      fields: {
        email_confirmation: false,
        payments_deposit_request: false,
        payment_deposit: false,
        payments_interim: false,
        payments_final_balance: false,
        litter_id: null,
      },
      loading: false,
      errors: {},
      currentTab: null,
      tab: null,
      fieldsStatus: {
        status: null,
      },
      statuses: [
        { name: "Pending", value: "Pending" },
        { name: "Active", value: "Active" },
        { name: "Complete", value: "Complete" },
        { name: "Rejected", value: "Rejected" },
        { name: "On Hold", value: "On Hold" },
        { name: "Unresponsive", value: "Unresponsive" },
      ],

      fieldsMessage: {
        message: null,
      },
      breadcrumbs: [
        {
          text: "Lomond Hills Labradoodles",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Applications",
          disabled: false,
          exact: true,
          to: {
            name: "module-lhl-applications-fch",
            params: { id: this.$route.params.id },
          },
        },
      ],
    };
  },

  methods: {
    openAddContact(application) {
      this.newContact.application = application;
      this.newContact.dialog = true;
    },

    openLinkToExising(application) {
      this.exising.application = application;
      this.exising.dialog = true;
    },

    saveExisingContact() {
      this.exising.loading = true;

      this.$store
        .dispatch("lhl/applications/exisingFchContact", {
          appId: this.$route.params.id,
          applicationId: this.exising.application.id,
        })
        .then(() => {
          this.resetExistingContact();
        })
        .catch(() => {
          this.exising.loading = false;
        });
    },

    saveAddContact() {
      this.newContact.loading = true;

      this.$store
        .dispatch("lhl/applications/newFchContact", {
          appId: this.$route.params.id,
          applicationId: this.newContact.application.id,
        })
        .then(() => {
          this.resetAddContact();
        })
        .catch(() => {
          this.newContact.loading = false;
        });
    },

    resetAddContact() {
      this.newContact.dialog = false;
      this.newContact.contact = {};
      this.newContact.loading = false;
    },

    resetExistingContact() {
      this.exising.dialog = false;
      this.exising.contact = {};
      this.exising.loading = false;
    },

    onTabChange() {
      this.currentTab = this.tab;
    },

    changeStatus() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        fields: this.fieldsStatus,
        applicationId: this.$route.params.applicationId,
      };

      this.fields.type = "status";

      this.$store
        .dispatch("lhl/applications/saveApplicationPuppy", payload)
        .then(() => {})
        .catch();
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        applicationId: this.$route.params.applicationId,
        fields: this.fieldsMessage,
      };

      payload.fields.type = "message";

      this.$store
        .dispatch("lhl/applications/saveApplicationPuppy", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.loading = false;
      this.isEditing = true;
      this.fieldsMessage = {
        message: null,
      };
    },

    openSwitch(application, field) {
      this.loading = true;
      this.errors = {};

      this.fields[field] = application.admins[field] ? 0 : 1;

      this.fields.type = "switch";

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        applicationId: application.id,
      };

      this.$store
        .dispatch("lhl/applications/saveApplicationPuppy", payload)
        .then(() => {})
        .catch();
    },
    getLitterUrlEmail() {
      if (this.application.admins.litter) {
        return (
          "<a target='_blank' href=" +
          process.env.VUE_APP_LHL_FORMS_URL +
          "/hidden-litter-page/" +
          this.application.admins.litter.unique_id +
          "> by following this Link</a>"
        );
      } else {
        return "";
      }
    },
  },

  mounted: function () {
    if (this.application.custommer_id == null) {
      this.$store
        .dispatch("lhl/applications/checkCustomerExist", {
          appId: this.$route.params.id,
          applicationId: this.$route.params.applicationId,
          fields: {
            first_name: this.application.admins.first_name,
            last_name: this.application.admins.last_name,
            email: this.application.admins.email,
          },
        })
        .then((resp) => {
          this.found = resp.data.status;
        });
    }

    this.fields.payment_deposit = this.application.admins.payment_deposit;
    this.fields.email_confirmation = this.application.admins.email_confirmation;
    this.fields.payments_deposit_request =
      this.application.admins.payments_deposit_request;
    this.fields.payments_final_balance =
      this.application.admins.payments_final_balance;
    this.fields.payments_interim = this.application.admins.payments_interim;

    this.fields.litter_id = this.application.admins.litter_id;

    this.fieldsMessage.message = this.application.admins.message
      ? this.application.admins.message
      : this.defaultMessageText;
  },

  computed: {
    currentUserRole() {
      let appId = this.$route.params.id;
      let roles = this.$store.getters.roles;

      roles = roles.filter(function (app) {
        return app.app_id == appId;
      });

      return roles[0];
    },

    defaultMessageText() {
      return ``;
    },

    application() {
      return this.$store.getters["lhl/applications/getFch"];
    },

    litters() {
      let litters = this.$store.getters["lhl/litters/all"];

      return litters.filter(
        (c) => c.status == "Planned" || c.status == "Due" || c.status == "Born"
      );
    },
  },
};
</script>
