<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit details</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="weight-form">
          <!-- <v-text-field
            label="First Name *"
            v-model="fields.first_name"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Last Name *"
            v-model="fields.last_name"
            type="text"
            outlined
            background-color="white"
          ></v-text-field> -->
          <v-text-field
            label="Email *"
            v-model="fields.email"
            type="email"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Phone *"
            v-model="fields.phone"
            type="phone"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="Daytime Telephone *"
            v-model="fields.daytime_telephone"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="Evening Telephone *"
            v-model="fields.evening_telephone"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="Address Line 1 *"
            v-model="fields.line_1"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Address Line 2"
            v-model="fields.line_2"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Address Line 3"
            v-model="fields.line_3"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="City *"
            v-model="fields.city"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="Postcode *"
            v-model="fields.postcode"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>
          <v-text-field
            label="County"
            v-model="fields.county"
            type="text"
            outlined
            background-color="white"
          ></v-text-field>

          <v-textarea
            label="Please list each family/household member's name, occupation and age (where appropriate) *"
            v-model="fields.list_household_members"
            type="text"
            outlined
            background-color="white"
          ></v-textarea>
          <v-textarea
            label="Please detail the primary and secondary care-givers' working schedule*"
            v-model="fields.carers_work_schedule"
            type="text"
            outlined
            background-color="white"
          ></v-textarea>

          <v-textarea
            label="Please detail provisions for the care of the dog when you are at work or not at home *"
            v-model="fields.care_provisions"
            type="text"
            outlined
            background-color="white"
          ></v-textarea>

          <v-textarea
            label="Please detail you previous experience as dog owners/carers *"
            v-model="fields.previous_ownership_experience"
            type="text"
            outlined
            background-color="white"
          ></v-textarea>

          <v-textarea
            label="Please detail any other pets and animals currently living on your property *"
            v-model="fields.additional_animals"
            type="text"
            outlined
            background-color="white"
          ></v-textarea>

          <v-textarea
            label="Does anybody in your household have a disability, disorder or particular need which should be taken into consideration? If so, please provide details"
            v-model="fields.household_disability"
            type="text"
            outlined
            background-color="white"
          ></v-textarea>

          <v-textarea
            label="Please details your plans for training your puppy. Who, when, where, how long for?"
            v-model="fields.puppy_training_details"
            type="text"
            outlined
            background-color="white"
          ></v-textarea>

          <v-select
            label="Where will your dog be living?"
            v-model="fields.dog_living_conditions"
            :items="living"
            outlined
            background-color="white"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="weight-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    applicationId: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      living: ["Indoors", "Outdoors"],
      fields: {
        list_household_members: null,
        carers_work_schedule: null,
        care_provisions: null,
        previous_ownership_experience: null,
        additional_animals: null,
        household_disability: null,
        puppy_training_details: null,
        dog_living_conditions: null,

        phone: null,
        email: null,
        mobile: null,
        evening_telephone: null,
        daytime_telephone: null,
        county: null,
        city: null,
        postcode: null,
        line_3: null,
        line_2: null,
        line_1: null,
        last_name: null,
        first_name: null,
      },
    };
  },

  // SELECTid:null,
  //  application_admin:null,
  //  term_accept_breeding:null,
  //  term_own_our_home:null,
  //  term_have_fully_enclosed_garden:null,
  //  term_within_geo_boundaries:null,
  //  term_accept_diet:null,
  //  term_adhere_to_basic_care_guidelines:null,
  //  term_attend_puppy_training:null,
  //  term_open_honest_communications:null,
  //  term_lhl_visitation:null,
  //  term_breeding_coordination:null,
  //  term_complete_health_testing:null,
  //  term_transport:null,
  //  term_lhl_sleep_over:null,
  //  term_cannot_opt_out:null,
  //  black_silver_blue:null,
  //  chocolate_cafe_lavender_parchment:null,
  //  red_gold:null,
  //  gold_cream:null,
  //  caramel:null,
  //  black_parti:null,
  //  chocolate_parti:null,

  //  size_first_choice:null,
  //  size_second_choice:null,
  //  size_third_choice:null,
  //  coat_type_first_choice:null,
  //  coat_type_second_choice:null,
  //  coat_type_third_choice:null,
  //  non_shedding:null,
  //  coat_colour_important:null,
  //  geo_boundary_description:null,
  //  anything_else_to_tell:null,
  //  found_lhl:null,
  //  data_consent:null,
  //  photo_identification:null,
  //  proof_of_address:null,
  //  photo_enclosed_garden:null,
  //  other_documents:null,
  //  created_at:null,
  //  updated_at:null,
  //  deleted_at`

  computed: {},

  methods: {
    openForm: function (application = null) {
      if (application !== null) {
        this.application = application;

        this.fields.list_household_members = application.list_household_members;
        this.fields.carers_work_schedule = application.carers_work_schedule;
        this.fields.care_provisions = application.care_provisions;
        this.fields.previous_ownership_experience =
          application.previous_ownership_experience;
        this.fields.additional_animals = application.additional_animals;
        this.fields.household_disability = application.household_disability;
        this.fields.puppy_training_details = application.puppy_training_details;
        this.fields.dog_living_conditions = application.dog_living_conditions;

        this.fields.phone = application.admins.phone;
        this.fields.email = application.admins.email;
        this.fields.mobile = application.admins.mobile;
        this.fields.evening_telephone = application.admins.evening_telephone;
        this.fields.daytime_telephone = application.admins.daytime_telephone;
        this.fields.county = application.admins.county;
        this.fields.city = application.admins.city;
        this.fields.postcode = application.admins.postcode;
        this.fields.line_3 = application.admins.line_3;
        this.fields.line_2 = application.admins.line_2;
        this.fields.line_1 = application.admins.line_1;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        applicationId: this.$route.params.applicationId,
        fields: this.fields,
      };

      payload.fields.type = "details";

      this.$store
        .dispatch("lhl/applications/saveApplicationFch", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.hashed_id = null;
      this.fields.customer_id = null;
      this.fields.breed_id = null;
    },
  },
};
</script>
